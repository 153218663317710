<template>
  <div class="main">
    <mtop />
    <div class="content">
      <div class="card" :class="show?'cardh5':''">
        <div class="box">
          <div class="item">
            <div class="title">TERMS & CONDITIONS</div>
            <div class="cont">
              Cupid Love Metaverse is a collection of digital artworks (NFTs)
              running on the Polygon network. This website is only an interface
              allowing participants to exchange digital collectibles. Users are
              entirely responsible for the safety and management of their own
              private Ethereum wallets and validating all transactions and
              contracts generated by this website before approval. Furthermore,
              as the Cupid Love Metaverse smart contract runs on the Polygon
              network, there is no ability to undo, reverse, or restore any
              transactions.
            </div>
            <div class="cont">
              This website and its connected services are provided “as is” and
              “as available” without warranty of any kind. By using this website
              you are accepting sole responsibility for any and all transactions
              involving Cupid Love Metaverse digital collectibles.
            </div>
          </div>
           <div class="item">
            <div class="title">OWNERSHIP</div>
            <div class="cont">
          i. You Own the NFT. Each Cupid is an NFT on the polygon blockchain. When you purchase an NFT, you own the underlying Cupid, the Art, completely. Ownership of the NFT is mediated entirely by the Smart Contract and the Polygon Network: at no point may we seize, freeze, or otherwise modify the ownership of any Cupid.
    </div>
            <div class="cont">
ii. Personal Use. Subject to your continued compliance with these Terms, Cupid Labs grants you a worldwide, royalty-free license to use, copy, and display the purchased Art, along with any extensions that you choose to create or use, solely forthe following purposes: (i) for your own personal, non-commercial use; (ii) as part of a marketplace that permits the purchase and sale of your Cupid / NFT, provided that the marketplace cryptographically verifies each Cupid owner’s rights to display the Art for their Cupid to ensure that only the actual owner can display the Art; or (iii) as part of a third party website or application that permits the inclusion, involvement, or participation of your Cupid, provided that the website/application cryptographically verifies each Cupid owner’s rights to display the Art for their Cupid to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Cupid leaves the website/application.
    </div>
            <div class="cont">
iii. Commercial Use. Subject to your continued compliance with these Terms, Cupid Labs grants you an unlimited, worldwide license to use, copy, and display the purchased Art for the purpose of creating derivative works based upon the Art (“Commercial Use”). Examples of such Commercial Use would e.g. be the use of the Art to produce and sell merchandise products (T-Shirts etc.) displaying copies of the Art. For the sake of clarity, nothing in this Section will be deemed to restrict you from (i) owning or operating a marketplace that permits the use and sale of Cupids generally, provided that the marketplace cryptographically verifies each Cupid owner’s rights to display the Art for their Cupid to ensure that only the actual owner can display the Art; (ii) owning or operating a third party website or application that permits the inclusion, involvement, or participation of Cupids generally, provided that the third party website or application cryptographically verifies each Cupid owner’s rights to display the Art for their Cupid to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased Cupid leaves the website/application; or (iii) earning revenue from any of the foregoing.

            </div>
          </div>
        </div>
      </div>
    </div>
    <mbottom />
  </div>
</template>

<script>
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
export default {
  data() {
    return {show:false};
  },
  components: {
    mtop,
    mbottom,
  },

        mounted() {
    if (this._isMobile()) {
      this.show=true;
    } else {
      this.show=this.$store.getters.phone;
    }    
  },
  watch: {
      '$store.getters.phone': function (val) {
      this.show = val
    },
    deep: true,
    immediate: true,
   },
  methods: {

      _isMobile() {
   let flag = navigator.userAgent.match(/(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
 return flag;   
},}
};
</script>


<style lang="scss" scoped>
.main {
  .content {    
      padding-top: 100px;
    background-size: 100% 100%;  
    .card {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .box {
        width: 1200px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
      margin: 80px auto;
        .item {
          width: 100%;
          font-family: Microsoft YaHei;
          margin-bottom: 34px;
          .title {
            font-size: 40px;
            line-height: 34px;
            font-weight: bold;
            color: #ff4576;
            position: relative;
            margin: 39px 0 0;
            text-align: left;
          }
          .cont {
            font-size: 24px;
            font-family: Arial;
            font-weight: 400;
            color: #333333;
            line-height: 36px;
            text-align: left;
            margin: 39px 0;
          }
        }
      }
    }
    .cardh5{
         .box {
        width: 100%;
        .item{
          width: calc(100% - 66px);
          padding: 33px;
        }
        }
    }
  }
}
</style>